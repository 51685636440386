.googlemaps_embed {
    .tac_activate_maps_noapi {
        .tac_float {
            background-image: url(../../../Packages/TC.Neos.CookieConsent/Icons/map-location-dot.svg);
            background-repeat: no-repeat;
            background-size: 12%;
            background-position: top 15% center;
        }
    }
}
