@import "nodetypes/neos_contentcollection";

body.neos-backend {
  padding-top: 0;

  > header {
    position: relative;
  }

  .ck-content .table table td, .ck-content .table table th {
    text-align: left;
  }
}
