.tc-neos-elements-button {
    &.full-width {
        .btn {
            width: 100%;
        }
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right
    }
}
