.tc-website-teamblock {
    padding: $margin-section 0;
    margin-bottom: 0;

    .team-block-container {
        @include make-container;
        @include make-container-max-widths;

        .team-block-header {
            @include make-row();

            .text-content {
                @include make-col-ready;
                @include make-col(8);

                display: flex;
                flex-direction: column;
                gap: 26px;

                .title {
                    strong {
                        font-weight: $medium;
                        color: $green-text;
                    }
                }

                .description {
                    font-size: 26px;
                    font-weight: $light;
                    line-height: 36px;
                }
            }
        }

        .employees-wrapper {
            @include make-row();

            margin-top: 88px;
            padding-left: percentage(divide(1, $grid-columns));

            .tc-website-employee {
                @include make-col-ready;
                @include make-col(10);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 95px 0;

        .team-block-container {
            @include make-container($gutter-container-mobile);

            .team-block-header {
                .text-content {
                    @include make-col(12);
                    gap: 15px;

                    .description {
                        font-size: 20px;
                        line-height: 27px;
                    }
                }
            }

            .employees-wrapper {
                margin-top: 75px;
                padding-left: 0;

                .tc-website-employee {
                    @include make-col(12);
                    @include make-col-offset(0);
                }
            }
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
