.neos-nodetypes-multicolumn {
    @include make-row();
    @include media-breakpoint-down(xs) {
        &.without-margin-bottom {
            margin-bottom: 0;
        }
    }

    > * {
        &:before {
            content: " ";
        }
    }

    &.internal-margins-small {
        @include make-row($grid-gutter-width * .5);
    }

    &.internal-margins-normal {
        @include make-row($grid-gutter-width);
    }

    &.internal-margins-large {
        @include make-row($grid-gutter-width * 2);
    }

    &.align-start {
        .column {
            align-items: flex-start;
        }
    }

    &.align-center {
        .column {
            align-items: center;
        }
    }

    &.align-end {
        .column {
            align-items: flex-end;
        }
    }

    &.justify-start {
        .column {
            justify-content: flex-start;
        }
    }

    &.justify-center {
        .column {
            justify-content: center;
        }
    }

    &.justify-end {
        .column {
            justify-content: flex-end;
        }
    }

    &.justify-space-between {
        .column {
            justify-content: space-between;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0 !important;
        }

        > .nodetypes-content:last-child {
            margin-bottom: 0;
        }

        &:before {
            display: none;
        }
    }

    // Inversion de 2 colonnes
    &.inverse-column {
        @include media-breakpoint-down(lg) {
            .column {
                &:first-child {
                    order: 2;
                    margin-bottom: 0 !important;
                }

                &:last-child {
                    order: 1;
                    margin-bottom: $grid-gutter-width !important;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &:not(.force-xs) {
            &.mobile-margin-bottom-none {
                margin-bottom: 0;
            }

            &.mobile-margin-bottom-small {
                margin-bottom: calc($grid-gutter-width) * .5;
            }

            &.mobile-margin-bottom-normal {
                margin-bottom: $grid-gutter-width;
            }

            &.mobile-margin-bottom-large {
                margin-bottom: $grid-gutter-width * 2;
            }
        }
    }
}

@each $column in $tc-neos-elements-bootstrap-multicolumns {
    $columnName: nth($column, 1);
    $columnNumber: nth($column, 2);

    .column-#{$columnName} {
        $columnDifference: calc(#{$grid-columns} - #{$columnNumber});

        @at-root .neos-nodetypes-multicolumn:not(.force-xs) & {
            @include media-breakpoint-only(xs) {
                margin-bottom: $tc-neos-elements-bootstrap-columns-xs-to-sm-margin-bottom;
            }
        }

        @at-root .neos-nodetypes-multicolumn:not(.force-sm) & {
            @include media-breakpoint-up(sm) {
                margin-bottom: $tc-neos-elements-bootstrap-columns-xs-to-sm-margin-bottom;
            }
        }

        @include make-col-ready();
        @include media-breakpoint-up(md) {
            @include make-col($columnNumber);
        }

        // Forcing du colonnage en SM
        @at-root .force-sm > & {
            @include media-breakpoint-up(sm) {
                @include make-col($columnNumber);
            }
        }

        // Forcing du colonnage en XS
        @at-root .force-xs > & {
            @include media-breakpoint-up(xs) {
                @include make-col($columnNumber);
            }
        }

        // Forcing du colonnage en XS mais pas en SM
        @at-root .force-xs:not(.force-sm) > & {
            &:first-child {
                @include media-breakpoint-up(sm) {
                    @include make-col($grid-columns);
                }
                @include media-breakpoint-up(md) {
                    @include make-col($columnNumber);
                }
            }

            &:last-child {
                @include media-breakpoint-up(sm) {
                    @include make-col($grid-columns);
                }
                @include media-breakpoint-up(md) {
                    @include make-col($columnNumber);
                }
            }
        }
    }
}
