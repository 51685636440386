.tc-website-eventregistrationformpage {
    #main-container {
        > .tc-website-pageintroduction {
            .page-introduction-container {
                .page-introduction-wrapper {
                    .introduction-title {
                        strong {
                            color: $purple;
                        }
                    }
                }
            }
        }
    }
}
