.tc-website-filedownload {
    .button {
        @include make-button;

        .ck-placeholder {
            &:before {
                color: #000;
            }
        }
    }
}
