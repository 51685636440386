.tc-website-blog {
    .blog-events-slider {
        background-color: $purple-light;
        margin-top: $margin-section;

        .blog-events-slider-container {
            @include make-container;
            @include make-container-max-widths;

            padding: $margin-section 0 calc($margin-section)-30px 0;

            .tc-website-blogeventsslider {
                margin: 0;

                .tc-neos-elements-swiper {
                    .swiper-wrapper {
                        .tc-website-blogeventsslideritem {
                            .item-wrapper {
                                .content-wrapper {
                                    .text-content {
                                        .category {
                                            color: #fff;
                                        }
                                    }

                                    .more-button {
                                        .btn.btn-3d {
                                            background-color: $purple-light;

                                            &:before {
                                                box-shadow: -10px -10px 20px 0px #CAC7FE, 10px 10px 20px 0px #A19BFF;
                                            }

                                            &:after {
                                                box-shadow: -10px -10px 20px 0px #CAC7FE inset, 10px 10px 20px 0px #A19BFF inset;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .tc-website-blogeventsslideritem {
                    background-color: $purple-light;

                    .item-wrapper {
                        .content-wrapper {
                            .text-content {
                                .category {
                                    color: #fff;
                                }
                            }

                            .more-button {
                                .btn.btn-3d {
                                    background-color: $purple-light;

                                    &:before {
                                        box-shadow: -10px -10px 20px 0px #CAC7FE, 10px 10px 20px 0px #A19BFF;
                                    }

                                    &:after {
                                        box-shadow: -10px -10px 20px 0px #CAC7FE inset, 10px 10px 20px 0px #A19BFF inset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .blog-events-slider {
            margin-top: 95px;

            .blog-events-slider-container {
                padding-top: 75px;
                padding-bottom: 45px;

                .tc-website-blogeventsslider {
                    .tc-neos-elements-swiper {
                        .swiper-wrapper {
                            .tc-website-blogeventsslideritem {
                                padding-left: 30px;
                                padding-right: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}
