.tc-neos-elements-swiper {
    padding-top: 60px;
    position: relative;
    margin-bottom: 0;

    .swiper-wrapper {
        .swiper-slide {
            &.tc-neos-elements-swiper-swiperimageitem {
                margin-bottom: 0;

                figure {
                    figcaption {
                        margin-top: 44px;

                        p {
                            font-size: 16px;
                            font-weight: $light;
                            line-height: 22px;
                        }
                    }
                }
            }

            &:not(.swiper-slide-active) {
                opacity: 0 !important;
            }
        }
    }

    .swiper-pagination {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        gap: 19px;
        height: 16px;

        .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><g filter="url(%23filter0_ii_4492_3133)"><ellipse cx="8.5" cy="8" rx="8.5" ry="8" fill="%23EBF0F5"/></g><defs><filter id="filter0_ii_4492_3133" x="-5" y="-5" width="27" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="5" dy="5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.858824 0 0 0 0 0.87451 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4492_3133"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-5" dy="-5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4492_3133" result="effect2_innerShadow_4492_3133"/></filter></defs></svg>');
            background-repeat: no-repeat;
            background-size: 16px;
            background-color: $purple;
            transition: background .4s ease-in-out;
            fill: $gray;
            box-shadow: -5px -5px 10px 0px #FFF inset, 5px 5px 10px 0px #D3DBDF inset;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background-image: none;
                fill: $purple;
                box-shadow: -10px -10px 20px 0px #B49CFF inset, 10px 10px 20px 0px #5C30E4 inset;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: 42px;

        .swiper-pagination {
            gap: 15px;

            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.neos-backend {
    .swiper-slide:not(.swiper-slide-active) {
        opacity: 1 !important;
    }
}
