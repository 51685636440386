.tc-website-blogarticle,
.tc-website-eventarticle {
    .tc-website-pageintroduction {
        margin-bottom: $margin-section;

        .banner-image {
            margin-bottom: 95px;
        }

        .blog-category {
            @include make-container;
            @include make-container-max-widths;

            margin-bottom: 26px;
            font-size: 16px;
            font-weight: $semi-bold;
            line-height: 22px;
            color: $purple;
        }

        .introduction-title {
            margin-bottom: 0;
        }
    }

    .article-introduction-container {
        @include make-container;
        @include make-container-max-widths;

        .article-introduction-wrapper {
            margin-bottom: 24px;
        }

        .teaser-text {
            padding-right: calc(percentage(divide(4, $grid-columns)) - $grid-gutter-width);
            font-size: 26px;
            font-weight: $light;
            line-height: 36px;
        }

        .registration-button {
            @include make-button;
            margin-top: 36px;
        }
    }

    .neos-contentcollection {
        .nodetypes-content {
            margin-bottom: 95px;

            &.tc-website-rootcontainer {
                padding-top: 95px;
            }

            &.neos-nodetypes-text {
                padding-right: calc(percentage(divide(4, $grid-columns)) - $grid-gutter-width);
            }

            &.neos-nodetypes-columnlayouts-twocolumn,
            &.neos-nodetypes-image,
            &.jonnitto-prettyembed-wrapper,
            &.tc-neos-elements-swiper {
                margin-left: percentage(divide(1, $grid-columns));
                margin-right: percentage(divide(1, $grid-columns));
            }

            .neos-nodetypes-columnlayouts-twocolumn {
                .column {
                    .nodetypes-content {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }

            &.tc-website-rootcontainer {
                margin-bottom: 0;
            }
        }

        .jonnitto-prettyembed-wrapper {
            margin-bottom: 95px;
            margin-left: percentage(divide(1, $grid-columns));
            margin-right: percentage(divide(1, $grid-columns));
        }
    }

    #main-container {
        > .neos-contentcollection {
            > .nodetypes-content:not(.tc-website-rootcontainer) {
                &:last-child {
                    margin-bottom: $margin-section;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 75px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .tc-website-pageintroduction {
            margin-bottom: 95px;

            .blog-category {
                @include make-container($gutter-container-mobile);
                margin-bottom: 15px;
            }
        }

        .article-introduction-container {
            @include make-container($gutter-container-mobile);

            .teaser-text {
                font-size: 20px;
                line-height: 27px;
                padding-right: 0;
            }
        }

        .neos-contentcollection {
            .nodetypes-content {
                margin-bottom: 75px;

                &.tc-website-rootcontainer {
                    padding-top: 75px;
                }

                &.neos-nodetypes-text {
                    padding-right: 0;
                }

                &.neos-nodetypes-columnlayouts-twocolumn,
                &.neos-nodetypes-image,
                &.jonnitto-prettyembed-wrapper,
                &.tc-neos-elements-swiper {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
