#tarteaucitronRoot {
    * {
        font-family: 'Open Sans', sans-serif;
    }

    #tarteaucitronAlertBig {
        justify-content: space-around;
        padding: 0 !important;

        @at-root .cookie-consent-bar-bottom-hidden & {
            display: none !important;
        }

        @at-root .cookie-consent-bar-bottom-visible & {
            display: flex !important;
        }

        #tarteaucitronCloseCross {
            display: none;
        }

        #tarteaucitronDisclaimerAlert {
            display: inline-block;
            padding: 20px 40px;
            white-space: nowrap;
        }

        .tarteaucitronCTAButton,
        #tarteaucitronCloseAlert,
        #tarteaucitronPrivacyUrl {
            flex: 1;
            width: 20%;
            margin: 0;

            color: #fff;
            text-decoration: underline;
            text-decoration-color: transparent;
            border-radius: 0;
            cursor: pointer;
            font-size: 16px;

            transition: all .4s ease-in-out;

            &:hover {
                text-decoration-color: #fff;
            }

            &.tarteaucitronAllow {
                background-color: $primary;
                color: #fff;
            }

            &.tarteaucitronDeny {
                background-color: #fff;
                color: $primary;

                &:hover {
                    text-decoration-color: $primary;
                }
            }

            > span {
                display: none;
            }
        }

        #tarteaucitronCloseAlert {
            background-color: transparent;
            color: #fff;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;

            #tarteaucitronCloseCross {
                display: block;
                background-image: url(../Images/Icone/xmark-solid.svg);
                background-repeat: no-repeat;
                background-size: 24px;
                font-size: 0;
                width: 24px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 4px;
            }

            #tarteaucitronDisclaimerAlert {
                padding: 20px 60px 20px 40px;
                text-align: center;
                white-space: normal;
                border-bottom: 1px solid $orange;
            }

            .tarteaucitronCTAButton,
            #tarteaucitronCloseAlert,
            #tarteaucitronPrivacyUrl {
                width: 100%;
                padding: 20px 0;
            }
        }

        #tarteaucitronDisclaimerAlert {
            order: 1;
        }

        #tarteaucitronPrivacyUrl {
            order: 2;
        }

        .tarteaucitronAllow {
            order: 3;
        }

        .tarteaucitronDeny {
            order: 4;
        }

        #tarteaucitronCloseAlert {
            order: 5;
        }
    }

    #tarteaucitron {
        #tarteaucitronServices {
            #tarteaucitronMainLineOffset {
                #tarteaucitronInfo {
                }
            }

            #tarteaucitronSave {
                background-color: #fff;
            }

            @include media-breakpoint-down(sm) {
                .tarteaucitronH1 {
                    line-height: 32px;
                }
            }
        }

        .tarteaucitronAllow,
        #tarteaucitronPrivacyUrlDialog,
        .tarteaucitronDeny {
            border-radius: 4px;
            padding: 8px 14px !important;
            margin: 0 !important;
            line-height: normal !important;
        }

        .tarteaucitronAllow,
        #tarteaucitronPrivacyUrlDialog {
            background-color: $primary !important;
            color: #fff !important;

            span {
                display: none;
            }
        }

        .tarteaucitronDeny {
            background-color: #fff !important;
            color: $primary !important;

            span {
                display: none;
            }
        }
    }
}

.tac_activate {
    background: #ccc !important;

    .tac_float {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 100%;
        font-size: 16px;
        line-height: 20px;
        color: #1a1a1a;

        .tarteaucitronAllow {
            background-color: $primary !important;
        }
    }
}
