.tc-website-blogeventsslideritem {
    background-color: $gray;
    padding-bottom: 30px;

    .item-wrapper {
        @include make-row;
        align-items: flex-start;

        .content-wrapper,
        .image-wrapper {
            @include make-col-ready;
        }

        .content-wrapper {
            @include make-col(4);
            @include make-col-offset(1);

            display: flex;
            padding-top: 64px;
            flex-direction: column;
            gap: 44px;
            position: relative;
            left: 20px;

            .text-content {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .category {
                    font-size: 16px;
                    font-weight: $semi-bold;
                    line-height: 22px;
                    color: $purple;
                }
            }

            .more-button {
                @include make-button;
            }
        }

        .image-wrapper {
            @include make-col(5);
            @include make-col-offset(1);
        }
    }

    @include media-breakpoint-down(lg) {
        .item-wrapper {
            flex-direction: column;
            gap: 30px;

            .image-wrapper,
            .content-wrapper {
                @include make-col(12);
                @include make-col-offset(0);
            }

            .image-wrapper {
                order: 1;

                img {
                    width: 100%;
                }
            }

            .content-wrapper {
                order: 2;
                padding: 0;
                gap: 30px;
                left: 10px;

                .text-content {
                    gap: 15px;
                    padding-right: 20px;
                }
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.neos-backend {
    .tc-website-blogeventsslideritem {
        padding-bottom: 20px;

        .content-wrapper {
            padding-top: 0;
        }
    }
}
