.file-download-popup {
    .form-wrapper {
        width: 50vw;
        margin: auto;
        position: relative;

        .tc-website-filedownloadform {
            > .neos-form-builder-form {
                background-color: $white;
                padding: $grid-gutter-width * 2;
                border-radius: 18px;
                margin: 0;

                form {
                    > fieldset {
                        > p+p {
                            margin-bottom: $grid-gutter-width;
                        }
                    }
                }

                .actions {
                    margin-top: 44px;
                }

                .confirmation-download {
                    > div {
                        margin-bottom: $grid-gutter-width * .5;
                    }

                    a {
                        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="%233CCDA0" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>');
                        background-repeat: no-repeat;
                        background-position: left center;
                        padding-left: 32px;
                    }
                }
            }
        }

        .mfp-close {
            color: #000;
            font-size: 40px;
            opacity: 1;
        }

        @include media-breakpoint-down(lg) {
            width: 75vw;
        }

        @include media-breakpoint-down(md) {
            .tc-website-filedownloadform {
                > .neos-form-builder-form {
                    padding: $grid-gutter-width;
                }
            }

            .mfp-close {
                top: 12px;
            }
        }

        @include media-breakpoint-down(sm) {
            width: 90vw;
        }
    }
}
