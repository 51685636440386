.tc-website-pagenavigationitem {
    a {
        display: flex;
        gap: 29px;
        align-items: center;
        text-decoration: none;

        .navigation-icon {
            position: relative;
            display: flex;
            width: 86px;
            height: 42px;
            border-radius: 50px;
            justify-content: center;
            align-items: center;
            background-repeat: no-repeat;
            background-size: 11px 23px;
            background-position: center center;
            opacity: 1;

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50px;
                transition: opacity .4s ease-in-out;
            }

            &:before {
                box-shadow: -10px -10px 20px 0px #FFF, 10px 10px 20px 0px #D3DBDF;
                opacity: 1;
            }

            &:after {
                box-shadow: -10px -10px 20px 0px #FFF inset, 10px 10px 20px 0px #D3DBDF inset;
                opacity: 0;
            }

            &:hover {
                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        span {
            font-size: 16px;
            font-weight: $light;
            line-height: 22px;
            color: #000;
        }
    }

    &.previous {
        a {
            .navigation-icon {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="27" viewBox="0 0 16 27" fill="none"><path d="M14 2L3 13L14 25" stroke="black" stroke-width="3"/></svg>');
            }
        }
    }

    &.next {
        a {
            .navigation-icon {
                order: 2;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="27" viewBox="0 0 16 27" fill="none"><path d="M2 2L13 13L2 25" stroke="black" stroke-width="3"/></svg>');
            }

            span {
                order: 1;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        width: 48px;
        height: 26px;

        a {
            .navigation-icon {
                width: 48px;
                height: 26px;

                &:before {
                    box-shadow: -6px -6px 10px 0px #FFF, 6px 6px 10px 0px #D3DBDF;
                }

                &:after {
                    box-shadow: -6px -6px 10px 0px #FFF inset, 6px 6px 10px 0px #D3DBDF inset;
                }
            }
        }

        &.previous,
        &.next {
            a {
                .navigation-icon {
                    background-size: 10px 13px;
                }
            }
        }

        &.previous {
            margin-left: 16px;

            a {
                .navigation-icon {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none"><path d="M8 1L2.34315 6.65685L8 12.3137" stroke="black" stroke-width="2"/></svg>');
                }
            }
        }

        &.next {
            margin-right: 16px;

            a {
                .navigation-icon {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none"><path d="M1.65625 1L7.3131 6.65685L1.65625 12.3137" stroke="black" stroke-width="2"/></svg>');
                }
            }
        }
    }
}
