.tc-website-featuredservices {
    padding: $margin-section 0;
    background-color: $blue-light;
    margin-bottom: 0;

    .featured-services-container {
        @include make-container;
        @include make-container-max-widths;

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 95px;

            .text-content {
                max-width: 890px;

                h2 {
                    margin-bottom: 22px;

                    strong {
                        font-weight: $medium;
                        color: $green-text;
                    }
                }

                .description {
                    font-size: 26px;
                    font-weight: $light;
                    line-height: 36px;
                }
            }

            .button {
                @include make-button;
            }
        }

        .services-wrapper {
            @include make-row(106px);

            .tc-website-featuredservicesitem {
                @include make-col-ready;
                @include make-col(4);

                &:nth-child(n+4) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.services-count-1 {
        .services-wrapper {
            justify-content: center;
        }
    }

    &.services-count-2,
    &.services-count-4 {
        .services-wrapper {
            @include make-row;

            .tc-website-featuredservicesitem {
                @include make-col-offset(1);

                &:nth-child(even) {
                    @include make-col-offset(2);
                }

                @include media-breakpoint-down(lg) {
                    margin-left: 0;

                    &:nth-child(even) {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &.services-count-4 {
        .services-wrapper {
            .tc-website-featuredservicesitem {
                &:nth-child(n+3) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.services-count-4,
    &.services-count-5,
    &.services-count-6 {
        .services-wrapper {
            .tc-website-featuredservicesitem {
                margin-bottom: 106px;
            }
        }
    }

    &.layout-horizontal {
        .featured-services-container {
            .services-wrapper {
                display: block;

                .tc-website-featuredservicesitem {
                    @include make-col(9);
                    @include make-col-offset(2);
                    margin-bottom: 90px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 95px 0;

        .featured-services-container {
            @include make-container($gutter-container-mobile);

            .header {
                flex-direction: column;
                margin-bottom: 75px;

                .text-content {
                    max-width: none;

                    .description {
                        font-size: 20px;
                        line-height: 27px;
                    }
                }

                .button {
                    margin-top: 30px;
                }
            }

            .services-wrapper {
                @include make-row(0);
                gap: 75px;

                .tc-website-featuredservicesitem {
                    @include make-col($grid-columns);

                    margin-bottom: 0;
                }
            }
        }

        &.layout-horizontal {
            .featured-services-container {
                .services-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 75px;

                    .tc-website-featuredservicesitem {
                        @include make-col($grid-columns);
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
