.tc-website-blogeventsslider {
    .tc-neos-elements-swiper {
        padding-top: 0;

        .swiper-pagination {
            left: calc(percentage(divide(1, $grid-columns)) + 18px);
        }
    }

    @include media-breakpoint-down(lg) {
        margin-left: $gutter-container-mobile * -.5;
        margin-right: $gutter-container-mobile * -.5;

        .tc-neos-elements-swiper {
            padding-top: 42px;

            .swiper-wrapper {
                .tc-website-blogeventsslideritem {
                    padding-left: $gutter-container-mobile * .5;
                    padding-right: $gutter-container-mobile * .5;
                }
            }

            .swiper-pagination {
                left: $gutter-container-mobile * .5;
            }
        }
    }
}
