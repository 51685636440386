.nodetypes-content {
    margin-bottom: $margin-section;

    @include media-breakpoint-down(lg) {
        margin-bottom: 95px;
    }

    &.margin-bottom-none {
        margin-bottom: 0;
    }

    &.margin-bottom-small {
        margin-bottom: $margin-bottom-small;
    }

    &.margin-bottom-default {
        margin-bottom: $margin-bottom-default;
    }

    &.margin-bottom-big {
        margin-bottom: $margin-bottom-big;
    }

    table {
        width: 100%;

        @include media-breakpoint-down(lg) {
            overflow-wrap: break-word;
        }

        tr {
            th {
                white-space: nowrap;
                font-weight: $light;
            }

            td,
            th {
                padding: 10px 15px;
            }

            &:hover {
                background-color: $blue-light;
            }

            @include media-breakpoint-down(lg) {
                td,
                th {
                    padding: 10px;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    @at-root .neos-contentelement-active &, &.neos-contentelement-active {
        table {
            border: 1px solid black;

            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th,
                    > td {
                        border: 1px solid black;
                    }
                }
            }
        }
    }
}
