.tc-neos-elements-map {
    h2.title {
        margin-bottom: 44px;
    }

    .map-container,
    .caption {
        margin: 0 calc(percentage(divide(1, $grid-columns)) + $grid-gutter-width);
    }

    .map-container {
        height: 650px;
    }

    .caption {
        margin-top: 26px;
    }

    @include media-breakpoint-down(lg) {
        h2.title {
            margin-bottom: 15px;
        }

        .map-container,
        .caption {
            margin-left: 0;
            margin-right: 0;
        }

        .map-container {
            height: 186px;
        }

        .caption {
            margin-top: 15px;
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
