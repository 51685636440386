.dl-gallery-gallery {
    &.theme-bootstrapLightbox {
        > .dl-gallery {
            > .row.dl-gallery {
                > .neos-nodetypes-image {
                    margin-bottom: $grid-gutter-width;
                }
            }
        }
    }
}
