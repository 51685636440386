.tc-website-testimonialslideritem {
    margin-bottom: 0;

    .testimonial-item-wrapper {
        max-width: percentage(divide(8, $grid-columns));
        background-color: $gray;
        display: flex;
        flex-direction: column;
        gap: 44px;

        .testimonial {
            font-size: 26px;
            font-weight: $light;
            line-height: 36px;

            &:before {
                content: "«";
                display: inline;
            }

            &:after {
                content: "»";
                display: inline;
            }
        }

        .author-wrapper {
            display: flex;
            gap: 71px;
            align-items: center;

            .author-photo {
                img {
                    border-radius: 50%;
                }
            }

            .author-info {
                font-size: 16px;
                line-height: 22px;

                .author-name {
                    font-weight: $semi-bold;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .testimonial-item-wrapper {
            max-width: none;
            gap: 30px;

            .testimonial {
                font-size: 20px;
                line-height: 27px;
            }

            .author-wrapper {
                gap: 39px;

                .author-info {
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }
    }
}

.neos-backend {
    .tc-website-testimonialslideritem {
        margin-bottom: $grid-gutter-width * 2;
    }
}
