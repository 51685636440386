.neos-nodetypes-text {
    a {
        color: #000;
        font-size: 18px;
        font-weight: $light;
        line-height: 26px;
        text-decoration-line: underline;
    }

    h2, h3, p {
        margin-bottom: 24px;

        @include media-breakpoint-down(lg) {
            margin-bottom: 15px;
        }
    }

    h2, h3 {
        strong {
            font-weight: $medium;
            color: $green-text;
        }
    }
}
