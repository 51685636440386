.tc-website-blogarticlepagination {
    padding-bottom: $margin-section;

    .pagination-container {
        @include make-container;
        @include make-container-max-widths;

        .pagination-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pagination-mobile-text {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding-bottom: 95px;

        .pagination-container {
            @include make-container($gutter-container-mobile);

            .pagination-wrapper {
                .tc-website-pagenavigationitem {
                    a {
                        span {
                            display: none;
                        }
                    }
                }

                .pagination-mobile-text {
                    display: block;
                }
            }
        }
    }
}
