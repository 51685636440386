.tc-website-pageintroduction {
    .banner-image {
        @include make-container;
        @include make-container-max-widths;

        margin-bottom: 110px;

        img {
            width: 100%;
        }
    }

    .introduction-text-container {
        @include make-container;
        @include make-container-max-widths;
    }

    .introduction-title {
        padding-right: calc(percentage(divide(5, $grid-columns)) - $grid-gutter-width);
        margin-bottom: 44px;

        strong {
            font-weight: $light;
            color: $green-text;

            @at-root .color-purple & {
                color: $purple;
            }
        }
    }

    .introduction-text {
        max-width: percentage(divide(8, $grid-columns));
        font-size: 26px;
        font-weight: $light;
        line-height: 36px;
    }

    .page-introduction-button {
        @include make-button;

        .ck-editor__editable_inline {
            position: relative;
            z-index: 2;
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }

    + .neos-contentcollection {
        > .nodetypes-content:first-child {
            &.tc-website-featuredservices,
            &.tc-website-accordionblock:not(.background-color-gray) {
                margin-top: $margin-section;

                @include media-breakpoint-down(lg) {
                    margin-top: 95px;
                }
            }
        }
    }

    + .neos-contentcollection[data-children-count="0"] {
        padding-top: $margin-section;
    }

    @include media-breakpoint-down(lg) {
        &.has-slider {
            flex-direction: column;
        }

        .banner-image {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
            width: 100vw;
            max-width: none;
            margin-bottom: 75px !important;
        }

        .introduction-text-container {
            @include make-container($gutter-container-mobile);

            .introduction-title {
                padding-right: 0;
                margin-bottom: 30px;
            }

            .introduction-text {
                max-width: none;
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    @at-root .contact-child-page & {
        margin-bottom: 0;
    }
}
