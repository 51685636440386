.neos-nodetypes-multicolumn  {
    > .column {
        .nodetypes-content {
            margin-bottom: 44px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        > .column {
            margin-bottom: 75px !important;
            padding-left: 0;
            padding-right: 0;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}
