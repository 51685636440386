.tc-website-breadcrumbmenu {
    padding: 0 0 $grid-gutter-width*2 0;

    .breadcrumb-container {
        @include make-container;
        @include make-container-max-widths;

        .breadcrumb-wrapper {
            ol, ul {
                margin: 0;
                padding: 0;
                display: flex;
                list-style: none;
                align-items: center;
                gap: $grid-gutter-width*.5;

                li {
                    &:not(:last-child) {
                        &:after {
                            content: "|";
                            position: relative;
                            right: -$grid-gutter-width * .25;
                        }
                    }
                }
            }
        }
    }
}
