#main-container{
    padding-top: calc($heightHeader + 100px);

    > .neos-contentcollection {
        > .neos-nodetypes-contentreferences-contentreferences {
            &:not(.no-container) {
                @include make-container;
                @include make-container-max-widths;

                @include media-breakpoint-down(lg) {
                    @include make-container($gutter-container-mobile);
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: calc($heightHeaderMobile + 30px);
    }
}
