.tc-neos-elements-button {
    @include make-button;
}

.neos-backend {
    .tc-neos-elements-button {
        .ck-editor__editable_inline {
            position: relative;
            z-index: 2;
        }
    }
}
