/***************
**** Lexend ****
***************/

// Light normal
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-Light.ttf') format('truetype'),
    url('../Fonts/Lexend-Light.woff') format('woff'),
    url('../Fonts/Lexend-Light.woff2') format('woff2');
    font-style: normal;
    font-weight: 300;
}

// Light italic
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-LightItalic.ttf') format('truetype'),
    url('../Fonts/Lexend-LightItalic.woff') format('woff'),
    url('../Fonts/Lexend-LightItalic.woff2') format('woff2');
    font-style: italic;
    font-weight: 300;
}

// Medium normal
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-Medium.ttf') format('truetype'),
    url('../Fonts/Lexend-Medium.woff') format('woff'),
    url('../Fonts/Lexend-Medium.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
}

// Medium italic
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-MediumItalic.ttf') format('truetype'),
    url('../Fonts/Lexend-MediumItalic.woff') format('woff'),
    url('../Fonts/Lexend-MediumItalic.woff2') format('woff2');
    font-style: italic;
    font-weight: 500;
}

// SemiBold normal
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-SemiBold.ttf') format('truetype'),
    url('../Fonts/Lexend-SemiBold.woff') format('woff'),
    url('../Fonts/Lexend-SemiBold.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
}

// SemiBold italic
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-SemiBoldItalic.ttf') format('truetype'),
    url('../Fonts/Lexend-SemiBoldItalic.woff') format('woff'),
    url('../Fonts/Lexend-SemiBoldItalic.woff2') format('woff2');
    font-style: italic;
    font-weight: 600;
}

// Bold normal
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-Bold.ttf') format('truetype'),
    url('../Fonts/Lexend-Bold.woff') format('woff'),
    url('../Fonts/Lexend-Bold.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
}

// Bold italic
@font-face {
    font-family: 'Lexend';
    src: url('../Fonts/Lexend-BoldItalic.ttf') format('truetype'),
    url('../Fonts/Lexend-BoldItalic.woff') format('woff'),
    url('../Fonts/Lexend-BoldItalic.woff2') format('woff2');
    font-style: italic;
    font-weight: 700;
}
