.tc-website-rootcontainer {
    padding: $margin-section 0;
    margin: 0;

    .root-container {
        @include make-container;
        @include make-container-max-widths;

        .content {
            > .neos-contentcollection {
                > .nodetypes-content {
                    &:last-child {
                        margin-bottom: 0;

                        > .nodetypes-content {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    + .tc-website-testimonialslider {
        padding-top: 0;
        background-position: top right;
    }

    + .tc-website-rootcontainer,
    + .tc-website-accordionblock {
        padding-top: 0 !important;
    }

    @include media-breakpoint-down(lg) {
        padding: 95px 0;

        .root-container {
            @include make-container($gutter-container-mobile);
        }
    }
}
