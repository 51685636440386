.tc-website-featuredservicesitem {
    text-align: center;

    .featured-service-item-wrapper {
        display: flex;
        flex-direction: column;
        gap: 44px;
        color: #000;
        text-decoration: none;
        height: 100%;

        .icon-wrapper {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before,
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 50%;
                z-index: 1;
                transition: opacity .4s ease-in-out;
            }

            &:before {
                box-shadow: -10px -10px 20px 0px #EEF6FF, 10px 10px 20px 0px #C9D6E5;
                opacity: 1;
            }

            &:after {
                box-shadow: -10px -10px 20px 0px #EEF6FF inset, 10px 10px 20px 0px #C9D6E5 inset;
                opacity: 0;
            }

            &:hover {
                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        .content-wrapper {
            display: flex;
            flex-direction: column;
            gap: 44px;
            justify-content: space-between;
            height: 100%;

            .text-content {
                display: flex;
                flex-direction: column;
                gap: 24px;
            }

            .more-button {
                @include make-button;

                .btn.btn-3d {
                    background-color: $blue-light;

                    &:before {
                        box-shadow: -10px -10px 20px 0px #EEF6FF, 10px 10px 20px 0px #C9D6E5;
                    }

                    &:after {
                        box-shadow: -10px -10px 20px 0px #EEF6FF inset, 10px 10px 20px 0px #C9D6E5 inset;
                    }

                    .ck-editor__editable_inline {
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }

    &.layout-horizontal {
        text-align: left;

        .featured-service-item-wrapper {
            flex-direction: row;
            gap: 200px;

            .icon-wrapper {
                order: 2;
                flex: 0 0 auto;
                margin: 0;
            }

            .content-wrapper {
                order: 1;

                .text-content {
                    h3 {
                        font-size: 36px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .featured-service-item-wrapper {
            gap: 30px;

            .icon-wrapper {
                width: 159px;
                height: 159px;
            }

            .content-wrapper {
                gap: 30px;

                .text-content {
                    gap: 15px;

                    h3,
                    .teaser {
                        padding: 0 percentage(divide(2.1, $grid-columns));
                    }
                }
            }
        }

        &.layout-horizontal {
            text-align: center;

            .featured-service-item-wrapper {
                flex-direction: column;
                gap: 30px;

                .icon-wrapper {
                    order: 1;
                    margin: auto;
                }

                .content-wrapper {
                    order: 2;

                    .text-content {
                        h3 {
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }
}
