.tc-website-featuredproducts {
    padding: $margin-section 0;
    background-color: $gray;
    margin-bottom: 0;

    .featured-products-container {
        @include make-container;
        @include make-container-max-widths;

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 95px;

            .text-content {
                flex: auto;

                h2 {
                    max-width: percentage(divide(6, $grid-columns));
                    margin-bottom: 22px;

                    strong {
                        font-weight: $medium;
                        color: $green-text;
                    }
                }

                .description {
                    max-width: percentage(divide(10, $grid-columns));
                    font-size: 26px;
                    font-weight: $light;
                    line-height: 36px;
                }
            }

            .button {
                @include make-button;
                flex: 0 0 auto;
            }
        }

        .products-wrapper {
            @include make-row(26px);

            .tc-website-featuredproductsitem {
                @include make-col-ready;
                @include make-col(4);

                &:nth-child(n+4) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.products-count-1 {
        .products-wrapper {
            justify-content: center;

            .tc-website-featuredproductsitem {
                @include make-col(6);

                &:not(.layout-horizontal) {
                    .featured-product-item-wrapper {
                        .content-wrapper {
                            .text-content {
                                padding: 0 90px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.products-count-2,
    &.products-count-4 {
        .products-wrapper {
            justify-content: center;

            .tc-website-featuredproductsitem {
                @include make-col(5);

                &:not(.layout-horizontal) {
                    .featured-product-item-wrapper {
                        .content-wrapper {
                            .text-content {
                                padding: 0 90px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.products-count-4 {
        .products-wrapper {
            .tc-website-featuredproductsitem {
                &:nth-child(n+3) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.layout-horizontal {
        .featured-products-container {
            .products-wrapper {
                display: block;

                .tc-website-featuredproductsitem {
                    @include make-col(8);
                    @include make-col-offset(2);

                    margin-bottom: 95px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        &.layout-horizontal {
            .featured-products-container {
                .products-wrapper {
                    .tc-website-featuredproductsitem {
                        @include make-col(9);
                        @include make-col-offset(1);
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 95px 0;

        .featured-products-container {
            @include make-container($gutter-container-mobile);

            .header {
                flex-direction: column;
                margin-bottom: 75px;

                .text-content {
                    max-width: none;

                    h2 {
                        max-width: none;
                    }

                    .description {
                        font-size: 20px;
                        line-height: 27px;
                        max-width: none;
                    }
                }

                .button {
                    margin-top: 30px;
                }
            }

            .products-wrapper {
                @include make-row(0);
                gap: 75px;

                .tc-website-featuredproductsitem {
                    @include make-col($grid-columns);
                }
            }
        }

        &.layout-horizontal {
            .featured-products-container {
                .products-wrapper {
                    .tc-website-featuredproductsitem {
                        @include make-col($grid-columns);
                        @include make-col-offset(0);

                        margin-bottom: 75px;
                    }
                }
            }
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }

    + .tc-website-rootcontainer {
        padding-top: 0;
    }
}
