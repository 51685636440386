.neos-form-builder-form {
    margin: 0 calc(percentage(divide(2, $grid-columns)) + $grid-gutter-width);

    fieldset {
        .clearfix {
            margin-bottom: 44px;

            > label {
                .required {
                    font-size: 26px;
                    font-weight: $medium;
                    line-height: 32px;
                    color: $green;
                    margin-left: 6px;
                }
            }

            .input {
                position: relative;

                .inputs-list {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    gap: 190px;

                    li {
                        > label {
                            margin: 0;

                            input {
                                position: absolute;
                                top: 0;
                                left: -9999px;
                                visibility: hidden;

                                &:checked + span {
                                    &:after {
                                        transform: translate3d(0, 0, 0);
                                        opacity: 1;
                                    }
                                }
                            }

                            span {
                                position: relative;
                                cursor: pointer;
                                padding-left: 64px;

                                &:before,
                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    border-radius: 50%;
                                    left: 0;
                                    width: 31px;
                                    height: 29px;
                                    margin: -14px 0 0;
                                    transition: opacity 0.25s ease-in-out;
                                }

                                &:before {
                                    opacity: 1;
                                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none"><g filter="url(%23filter0_ii_4492_3793)"><ellipse cx="15.5" cy="14.5" rx="15.5" ry="14.5" fill="%23EEF3F5"/></g><defs><filter id="filter0_ii_4492_3793" x="-5" y="-5" width="41" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="5" dy="5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.858824 0 0 0 0 0.87451 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4492_3793"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-5" dy="-5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4492_3793" result="effect2_innerShadow_4492_3793"/></filter></defs></svg>');
                                }

                                &:after {
                                    opacity: 0;
                                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none"><g filter="url(%23filter0_ii_4434_1172)"><ellipse cx="15.5" cy="14.5" rx="15.5" ry="14.5" fill="%233ECC9F"/></g><defs><filter id="filter0_ii_4434_1172" x="-5" y="-5" width="41" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="5" dy="5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.700863 0 0 0 0 0.477861 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4434_1172"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-5" dy="-5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.95734 0 0 0 0 0.652732 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4434_1172" result="effect2_innerShadow_4434_1172"/></filter></defs></svg>');
                                }
                            }
                        }
                    }

                    + .help-inline {
                        top: 40px;
                    }
                }

                .email-with-confirmation-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 24px;
                    align-items: flex-end;

                    input {
                        width: calc(50% - 12px);
                    }
                }

                textarea + .help-inline {
                    top: 310px;
                }
            }

            .event-select-dropdown {
                margin-bottom: 110px;
            }

            .form-check {
                display: flex;
                gap: 6px;
                position: relative;
                margin-bottom: 0;
                padding-left: 0;

                input {
                    position: absolute;
                    top: 0;
                    left: -9999px;
                    visibility: hidden;

                    &:checked + label {
                        &:after {
                            transform: translate3d(0, 0, 0);
                            opacity: 1;
                        }
                    }
                }

                label {
                    position: relative;
                    cursor: pointer;
                    padding-left: 64px;
                    font-size: 18px;
                    font-weight: $light;
                    line-height: 26px;
                    margin-bottom: 0;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        border-radius: 50%;
                        left: 0;
                        width: 31px;
                        height: 29px;
                        margin: -14px 0 0;
                        transition: opacity 0.25s ease-in-out;
                    }

                    &:before {
                        opacity: 1;
                        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none"><g filter="url(%23filter0_ii_4492_3793)"><ellipse cx="15.5" cy="14.5" rx="15.5" ry="14.5" fill="%23EEF3F5"/></g><defs><filter id="filter0_ii_4492_3793" x="-5" y="-5" width="41" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="5" dy="5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.858824 0 0 0 0 0.87451 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4492_3793"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-5" dy="-5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4492_3793" result="effect2_innerShadow_4492_3793"/></filter></defs></svg>');
                    }

                    &:after {
                        opacity: 0;
                        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none"><g filter="url(%23filter0_ii_4434_1172)"><ellipse cx="15.5" cy="14.5" rx="15.5" ry="14.5" fill="%233ECC9F"/></g><defs><filter id="filter0_ii_4434_1172" x="-5" y="-5" width="41" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="5" dy="5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.700863 0 0 0 0 0.477861 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4434_1172"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-5" dy="-5"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.95734 0 0 0 0 0.652732 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4434_1172" result="effect2_innerShadow_4434_1172"/></filter></defs></svg>');
                    }
                }

                &:after {
                    content: '*';
                    color: $green;
                    font-size: 18px;
                    font-weight: $light;
                    line-height: 26px;
                }
            }

            .consent-data-processing-checkbox {
                position: relative;

                .help-inline {
                    top: 30px;
                }
            }

            &.g-recaptcha {
                margin: 0;

                > .g-recaptcha {
                    .help-inline {
                        top: 30px;
                    }
                }
            }

            .help-inline {
                font-size: 16px;
                color: $green;
                position: absolute;
                top: 90px;
                left: 0;
            }
        }

        p {
            margin: 0;
        }

        > fieldset {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 24px;
            align-items: flex-end;
            margin-bottom: 44px;

            .clearfix {
                width: calc(50% - 12px);
                margin-bottom: 0;
            }
        }
    }

    .actions {
        margin-top: 95px;

        nav.form-navigation {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                .submit {
                    @include make-button;
                }
            }
        }
    }

    label[for="contact-form-recaptcha"] {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        margin-left: 0;
        margin-right: 0;

        fieldset {
            .clearfix {
                margin-bottom: 30px;

                > label {
                    .required {
                        font-size: 20px;
                        line-height: 27px;
                    }
                }

                .input {
                    .inputs-list {
                        gap: 75px;

                        li {
                            > label {
                                span {
                                    padding-left: 31px;

                                    &:before,
                                    &:after {
                                        width: 16px;
                                        height: 16px;
                                        margin: -8px 0 0;
                                        background-size: 16px 17px;
                                    }
                                }
                            }
                        }
                    }

                    .help-inline {
                        font-size: 14px;
                        top: auto;
                        bottom: -25px;
                    }

                    .email-with-confirmation-wrapper {
                        flex-direction: column;
                        gap: 30px;

                        input {
                            width: 100%;
                        }
                    }
                }

                .form-check {
                    display: inline;

                    .form-check-label {
                        font-size: 16px;
                        line-height: 22px;
                        padding-left: 0;
                        display: inline;
                        left: 31px;

                        &:before,
                        &:after {
                            width: 16px;
                            height: 16px;
                            margin: 0;
                            background-size: 16px 17px;
                            top: 3px;
                            left: -31px;
                        }
                    }

                    &:after {
                        position: relative;
                        left: 31px;
                    }
                }

                .consent-data-processing-checkbox {
                    .help-inline {
                        top: auto;
                    }
                }
            }

            > fieldset {
                .clearfix {
                    label {
                        width: calc(100vw - $gutter-container-mobile);
                    }

                    .input {
                        .help-inline {
                            bottom: -42px;
                        }
                    }
                }
            }
        }

        .actions {
            margin-top: 40px;
        }
    }
}

.grecaptcha-badge {
    z-index: 1;
}
