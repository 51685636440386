.toTop {
    opacity: 0;
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: #0d418c;
    color: white;
    padding: 15px;
    cursor: pointer;
    z-index: -999;
    transition: all 500ms linear;

    @include media-breakpoint-down(sm) {
        padding: 10px;
        right: 25px;
        bottom: 25px;
    }

    .icon {
        display: block;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        background-image: url(/_Resources/Static/Packages/TC.Website/Images/arrow-up-white.svg);
    }

    &.active {
        opacity: 1;
        z-index: 100;
    }
}
