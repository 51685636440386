.tc-website-freeeventregistrationform {
    margin-top: $margin-section;

    @include media-breakpoint-down(lg) {
        @include make-container($gutter-container-mobile);
        @include make-container-max-widths;

        margin-top: 95px;
    }
}
