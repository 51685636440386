.lenis {
    &.lenis-smooth {
        scroll-behavior: auto;

        [data-lenis-prevent] {
            overscroll-behavior: contain;
        }
    }

    &.lenis-stopped {
        overflow: hidden;
    }

    &.lenis-scrolling iframe {
        pointer-events: none;
    }
}
