.tc-website-blogarticleslist {
    padding: $margin-section 0 0;

    .articles-list-container {
        @include make-container;
        @include make-container-max-widths;

        .blog-articles-list-filter {
            @include make-row;
            margin-bottom: 95px;

            .filter-wrapper {
                @include make-col-ready;
                @include make-col($grid-columns * .5);

                display: flex;
                flex-direction: column;
                gap: 26px;
            }
        }

        .articles-list-wrapper {
            @include make-row(29px);

            .tc-website-blogarticleslistitem {
                @include make-col($grid-columns * .5);
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 95px 0;

        .articles-list-container {
            @include make-container($gutter-container-mobile);

            .blog-articles-list-filter {
                margin-bottom: 75px;

                .filter-wrapper {
                    @include make-col($grid-columns);

                    gap: 30px;
                }
            }

            .articles-list-wrapper {
                .tc-website-blogarticleslistitem {
                    @include make-col($grid-columns);
                }
            }
        }
    }
}
