.mmenu:not(.mm-menu) {
    display: none;
}

header {
    .menu-trigger {
        transition: all .4s ease-in-out;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 60px;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        border-radius: 50px;
        background: $gray;
        box-shadow: -6px -6px 10px 0px #FFF, 6px 6px 10px 0px #D3DBDF;

        > div {
            transition: all .4s ease-in-out;
            position: relative;
            background-color: #000;
            width: 32px;
            height: 2px;

            &:first-child {
                top: 0;
            }

            &:last-child {
                bottom: 0;
            }
        }

        @at-root .mm-wrapper--opened & {
            background: $green;
            box-shadow: -6px -6px 10px 0px #4EE5B5, 6px 6px 10px 0px #33AA84;

            > div {
                &:first-child {
                    top: 3px;
                    transform: rotate(22deg);
                }

                &:last-child {
                    bottom: 3px;
                    transform: rotate(-22deg);
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

nav.mmenu {
    transition: all .4s ease-in-out;
    z-index: -1 !important;
    opacity: 0 !important;
    display: flex !important;
    width: 100%;
    height: calc(100vh - $heightHeaderMobile);
    top: $heightHeaderMobile;
    background-color: $green;
    -webkit-tap-highlight-color: transparent;

    @at-root .mobile-menu-opened & {
        z-index: 99 !important;
        opacity: 1 !important;
    }

    @at-root .neos-backend & {
        display: none !important;
    }

    &.mm-menu_offcanvas {
        width: 100%;
        max-width: none;
        min-width: auto;
    }

    .mm-panels {
        @include make-container($gutter-container-mobile);
        @include make-container-max-widths;

        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        overflow-y: scroll;

        .mm-panel {
            position: static;
            background-color: transparent;
            padding-top: 32px;

            ul.mm-listview {
                padding-left: $gutter-container-mobile * .5;
                padding-right: $gutter-container-mobile * .5;
                position: relative;

                > li {
                    -webkit-tap-highlight-color: transparent;

                    &:not(.project-request-link) {
                        a {
                            font-size: 36px;
                            font-style: normal;
                            font-weight: $light;
                            line-height: 39px;
                            color: #000;
                            padding: 7.5px 0;
                            background-color: transparent;
                        }

                        &.current,
                        &.active {
                            a {
                                color: $gray;
                            }
                        }
                    }

                    &.project-request-link {
                        @include make-button;

                        display: inline-block;
                        margin-bottom: 15px;
                    }

                    &.logo-3d {
                        width: 100%;
                        height: 199px;
                        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="242" height="199" viewBox="0 0 242 199" fill="none"><g filter="url(%23filter0_ii_6398_2611)"><path d="M141.721 99.0841L90.3517 0H140.991L192.36 99.0841V99.9159L140.991 199H90.3517L141.721 99.9159V99.0841Z" fill="%233CCDA0"/><path d="M242.959 99.0841L294.276 0H243.584L192.372 99.0841V99.9159L243.792 199H294.38L242.959 99.9159V99.0841Z" fill="%233CCDA0"/><path d="M51.4797 99.0841L0.059082 0H50.6469L102.068 99.0841V99.9159L50.6469 199H0.059082L51.4797 99.9159V99.0841Z" fill="%233CCDA0"/></g><defs><filter id="filter0_ii_6398_2611" x="-5.94092" y="-6" width="306.321" height="211" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="6" dy="6"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.666667 0 0 0 0 0.517647 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_6398_2611"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-6" dy="-6"/><feGaussianBlur stdDeviation="5"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.305882 0 0 0 0 0.898039 0 0 0 0 0.709804 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_6398_2611" result="effect2_innerShadow_6398_2611"/></filter></defs></svg>');
                        background-position: right bottom;
                        background-repeat: no-repeat;
                        background-size: 294px 199px;
                        position: relative;
                        right: $gutter-container-mobile * -.5;
                        margin-bottom: $gutter-container-mobile * .5;
                        margin-top: 20px;
                    }

                    &:after {
                        display: none;
                    }
                }
            }

            &:after {
                display: none;
            }
        }
    }

    &.mm-menu--position-bottom {
        height: calc(100vh - $heightHeaderMobile);
    }
}

.mm-wrapper__blocker {
    display: none !important;
}

#tarteaucitronAlertSmall {
    z-index: 8;
}
