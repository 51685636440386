.neos-nodetypes-image {
    img {
        @include img-fluid();
    }

    figure {
        > a, > picture {
            display: inline-block;
        }

        figcaption {
            margin-top: 44px;

            p {
                font-size: 16px;
                font-weight: $light;
                line-height: 22px;

                strong {
                    font-weight: $medium;
                    color: #000;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        figure {
            figcaption {
                margin-top: 15px;

                p {
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }
    }
}
