.tc-neos-elements-bootstrap-accordion {
    margin-bottom: 0;

    &.accordion {
        .tc-neos-elements-bootstrap-accordionitem {
            &.accordion-item {
                background-color: transparent;
                border-top: 1.5px solid #000;
                border-radius: 0;
                border-left: 0;
                border-right: 0;

                .accordion-header {
                    .accordion-button {
                        transition: padding-bottom .4s ease-in-out;
                        background-color: transparent;
                        box-shadow: none;
                        padding: 36px 0;
                        display: flex;
                        justify-content: space-between;

                        font-size: 26px;
                        font-weight: $medium;
                        line-height: 32px;

                        > div {
                            padding-right: 60px;
                        }

                        &:after {
                            display: none;
                        }

                        strong {
                            font-weight: $medium;
                            color: $green-text;
                        }

                        .button {
                            width: 66px;
                            height: 42px;
                            border-radius: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 0 0 auto;
                            position: relative;

                            &:before,
                            &:after {
                                transition: opacity .4s ease-in-out;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                content: "";
                                display: block;
                                border-radius: 50px;
                            }

                            &:before {
                                box-shadow: -10px -10px 20px 0px #FFF, 10px 10px 20px 0px #D3DBDF;
                                opacity: 1;
                            }

                            &:after {
                                opacity: 0;
                                box-shadow: -10px -10px 20px 0px #FFF inset, 10px 10px 20px 0px #D3DBDF inset;
                            }

                            &:hover {
                                &:before {
                                    opacity: 0;
                                }

                                &:after {
                                    opacity: 1;
                                }
                            }

                            .icon {
                                content: "";
                                display: block;
                                width: 23px;
                                height: 11px;
                                background-color: transparent !important;
                                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="27" height="16" viewBox="0 0 27 16" fill="none"><path d="M25 2L14 13L2 2" stroke="black" stroke-width="3"/></svg>');
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: 100%;
                                transition: transform .4s ease-in-out;
                                position: relative;
                                z-index: 2;
                            }
                        }

                        &:not(.collapsed) {
                            color: #000;
                            padding-bottom: 24px;

                            .button {
                                &:before {
                                    opacity: 0;
                                }

                                &:after {
                                    opacity: 1;
                                }

                                .icon {
                                    transform: rotate(-180deg);
                                }
                            }
                        }
                    }
                }

                .accordion-body {
                    padding: 0 calc($grid-gutter-width + percentage(divide(1, $grid-columns))) 95px 0;

                    .nodetypes-content {
                        margin-bottom: 44px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        strong {
                            font-weight: $medium;
                            color: $black;
                        }
                    }
                }

                &:last-child {
                    border-bottom: 1.5px solid #000;
                }
            }

            @include media-breakpoint-down(lg) {
                &.accordion-item {
                    .accordion-header {
                        .accordion-button {
                            padding: 15px 0;
                            font-size: 20px;
                            line-height: 27px;
                            gap: 24px;

                            > div {
                                padding-right: 20px;
                            }

                            .button {
                                width: 48px;
                                height: 26px;

                                .icon {
                                    width: 14px;
                                    height: 9px;
                                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none"><path d="M1 1.65674L6.65685 7.31359L12.3137 1.65674" stroke="black" stroke-width="2"/></svg>');
                                }

                                &:hover {
                                    &:before {
                                        opacity: 1;
                                    }

                                    &:after {
                                        opacity: 0;
                                    }
                                }
                            }

                            &:not(.collapsed) {
                                .button {
                                    &:before {
                                        opacity: 0 !important;
                                    }

                                    &:after {
                                        opacity: 1 !important;
                                    }
                                }
                            }
                        }
                    }

                    .accordion-body {
                        padding: 0;
                        margin-bottom: 45px;
                    }
                }
            }
        }
    }

    &.background-color-blue-light {
        &.accordion {
            .tc-neos-elements-bootstrap-accordionitem {
                &.accordion-item {

                    .accordion-header {
                        .accordion-button {
                            .button {
                                background-color: $blue-light;

                                &:before {
                                    box-shadow: -10px -10px 20px 0px #EEF6FF, 10px 10px 20px 0px #C9D6E5;
                                }

                                &:after {
                                    box-shadow: -10px -10px 20px 0px #EEF6FF inset, 10px 10px 20px 0px #C9D6E5 inset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
