// Colors
$green: #3CCDA0;
$green-light: #BFE3D3;
$green-text: #1EB48C;
$purple: #7850EB;
$purple-light: #B9B4FF;
$blue-background: #AFCDEB;
$blue-dark: #121B5B;
$blue-light: #E1EBF5;
$blue-green: #90D8D5;
$gray: #EBF0F5;
$white: $gray;

$font-color: #000;
$link-color: $font-color;

// Font
$lexend: 'Lexend', sans-serif;

$light: 300;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// Margins
$margin-bottom-small: $grid-gutter-width * .5;
$margin-bottom-default: $grid-gutter-width;
$margin-bottom-big: $grid-gutter-width * 2;

$margin-section: 140px;

// Gutters
$gutter-container-mobile: 60px;

// Elements sizes
$heightHeader: 169px;
$heightHeaderMobile: 111px;
