.tc-website-contactblock {
    padding: $margin-section 0;
    background-color: $blue-background;

    .contact-block-container {
        @include make-container;
        @include make-container-max-widths;

        .contact-block-wrapper {
            @include make-row;

            .image-wrapper,
            .content-wrapper {
                @include make-col-ready;
            }

            .image-wrapper {
                @include make-col(5);
                @include make-col-offset(1);

                margin-top: 7px;

                img {
                    width: 100%;
                }
            }

            .content-wrapper {
                @include make-col(4);
                @include make-col-offset(1);

                display: flex;
                flex-direction: column;
                gap: 44px;

                .text-content {
                    display: flex;
                    flex-direction: column;
                    gap: 26px;

                    a {
                        color: #000;
                    }
                }

                .button-wrapper {
                    @include make-button;

                    .btn.btn-3d {
                        background-color: $blue-background;

                        &:before {
                            box-shadow: -10px -10px 20px 0px #CEE7FF, 10px 10px 20px 0px #93B2D0;
                        }

                        &:after {
                            box-shadow: -10px -10px 20px 0px #CEE7FF inset, 10px 10px 20px 0px #93B2D0 inset;
                        }
                    }

                    .ck-editor__editable_inline {
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 95px 0;

        .contact-block-container {
            @include make-container($gutter-container-mobile);

            .contact-block-wrapper {
                flex-direction: column;
                gap: 30px;

                .image-wrapper,
                .content-wrapper {
                    @include make-col($grid-columns);
                    @include make-col-offset(0);
                }

                .image-wrapper {
                    margin-top: 0;
                }

                .content-wrapper {
                    gap: 30px;

                    .text-content {
                        gap: 15px;

                        h2 {
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
