.tc-website-homeslideritem {
    .tc-website-homeslideritem {
        img {
            width: 100%;
        }
    }
}

.neos-backend {
    .tc-website-homeslideritem {
        margin-bottom: $grid-gutter-width;
        width: 222px;
        margin-right: $grid-gutter-width;
        float: left;

        img {
           max-width: 100%;
        }

        &:nth-child(even) {
            margin-right: 0;
        }
    }
}
