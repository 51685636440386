.grecaptcha-badge {
    bottom: 50px !important;
}

.g-recaptcha.clearfix {
    margin: 0;
    height: 0;

    &.disabled {
        height: auto;
    }
}
