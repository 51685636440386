.tc-website-employee {
    margin-bottom: 105px;

    .employee-wrapper {
        @include make-row;

        .image-wrapper,
        .content-wrapper {
            @include make-col-ready;
        }

        .image-wrapper {
            @include make-col(6);

            margin-top: 7px;
        }

        .content-wrapper {
            @include make-col(5);
            @include make-col-offset(1);

            display: flex;
            flex-direction: column;
            gap: 26px;

            .name-function {
                .function {
                    color: $green-text;

                    .ck-placeholder:before {
                        color: $green-text !important;
                    }
                }
            }

            .coordinates {
                margin-top: 10px;

                .coordinate {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .icon {
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        background-repeat: no-repeat;
                        background-size: 100%;

                        &.phone {
                            background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>');
                        }

                        &.email {
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>');
                        }

                        &.linkedin {
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>');
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 75px;

        .employee-wrapper {
            gap: 30px;

            .image-wrapper,
            .content-wrapper {
                @include make-col(12);
                @include make-col-offset(0);
            }

            .image-wrapper {
                margin-top: 0;
            }

            .content-wrapper {
                gap: 15px;
            }
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
