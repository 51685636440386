.tc-website-featuredproductsitem {
    text-align: center;

    .featured-product-item-wrapper {
        display: flex;
        flex-direction: column;
        gap: 44px;
        color: #000;
        padding: 22px 0 70px;
        text-decoration: none;
        height: 100%;

        box-shadow: -10px -10px 20px 0px #FFF inset, 10px 10px 20px 0px #D3DBDF inset;
        border-radius: 18px;

        .icon-wrapper {
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;

            img {
                max-height: 70px;
                max-width: 280px;
            }
        }

        .content-wrapper {
            display: flex;
            flex-direction: column;
            gap: 44px;
            justify-content: space-between;
            height: 100%;

            .text-content {
                display: flex;
                flex-direction: column;
                gap: 22px;
                padding: 0 40px;
            }

            .more-button {
                @include make-button;

                .btn.btn-3d {
                    background-color: $blue-light;

                    &:before {
                        box-shadow: -10px -10px 20px 0px #EEF6FF, 10px 10px 20px 0px #C9D6E5;
                    }

                    &:after {
                        box-shadow: -10px -10px 20px 0px #EEF6FF inset, 10px 10px 20px 0px #C9D6E5 inset;
                    }

                    .ck-editor__editable_inline {
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }

    &.layout-horizontal {
        text-align: left;

        .featured-product-item-wrapper {
            @include make-row;

            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0;
            align-items: center;
            padding: 95px 30px 95px 88px;

            .icon-wrapper {
                @include make-col-ready;
                @include make-col(6);
            }

            .icon-wrapper {
                order: 2;
                margin: 0;
                height: auto;
            }

            .content-wrapper {
                order: 1;

                .text-content {
                    padding: 0;

                    h3 {
                        font-size: 36px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        &.layout-horizontal {
            .featured-product-item-wrapper {
                .icon-wrapper {
                    img {
                        max-height: 58px;
                        max-width: 230px;
                    }
                }
            }
        }

        .featured-product-item-wrapper {
            .icon-wrapper {
                img {
                    max-height: 58px;
                    max-width: 230px;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .featured-product-item-wrapper {
            gap: 30px;
            padding: 60px 30px 75px 30px;

            .icon-wrapper {
                height: auto;

                img {
                    max-height: 40px;
                    max-width: 160px;
                }
            }

            .content-wrapper {
                gap: 30px;

                .text-content {
                    gap: 18px;
                    padding: 0 !important;
                }
            }
        }

        &.layout-horizontal {
            .featured-product-item-wrapper {
                flex-direction: column;
                gap: 30px;
                padding: 60px 30px 75px 30px;

                .icon-wrapper {
                    order: 1;
                    width: 100%;

                    img {
                        max-height: 40px;
                        max-width: 160px;
                    }
                }

                .content-wrapper {
                    order: 2;
                    align-items: center;

                    .text-content {
                        align-items: center;

                        .subtitle,
                        .teaser {
                            text-align: center;
                        }

                        .subtitle {
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }
}
