.neos-alignment-right {
    text-align: right;
}

.neos-alignment-left {
    text-align: left;
}

.neos-alignment-center {
    text-align: center;
}
