.tc-neos-elements-space {
    display: flex;
    align-items: center;
    height: $grid-gutter-width;
    margin-bottom: 0;

    hr {
        flex: 0 0 100%;
        margin: 0;
    }
}
