.tc-neos-elements-map {
    &.tac-disabled {
        .tac_activate {
            min-height: 500px;
            height: 100%;
            width: 100%;

            .tac_float {
                gap: 5%;

                .actions {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }
}
