.tc-website-blogarticleslistitem {
    @include make-col-ready;
    margin-bottom: $margin-section;

    .article-wrapper {
        display: flex;
        flex-direction: column;
        gap: 44px;

        .image-wrapper {
            img {
                width: 100%;
            }
        }

        .content-wrapper {
            display: flex;
            flex-direction: column;
            gap: 44px;

            .text-content {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .blog-category {
                    font-size: 16px;
                    font-weight: $semi-bold;
                    line-height: 22px;
                    color: $purple;
                }
            }

            .more-button {
                @include make-button;
            }
        }
    }

    &.full-width {
        width: 100% !important;

        .article-wrapper {
            @include make-row();
            flex-direction: row;
            gap: 0;

            .content-wrapper,
            .image-wrapper {
                @include make-col-ready();
            }

            .image-wrapper {
                @include make-col(5);
                @include make-col-offset(1);
                order: 2;
            }

            .content-wrapper {
                @include make-col(4);
                @include make-col-offset(1);
                order: 1;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        gap: 30px;
        margin-bottom: 75px;

        .article-wrapper {
            gap: 30px;

            .content-wrapper {
                gap: 30px;

                .text-content {
                    gap: 15px;
                }
            }
        }

        &.full-width {
            .article-wrapper {
                flex-direction: column;
                gap: 30px;

                .content-wrapper,
                .image-wrapper {
                    @include make-col($grid-columns);
                }

                .image-wrapper {
                    @include make-col-offset(0);
                    order: 1;
                }

                .content-wrapper {
                    @include make-col-offset(0);
                    order: 2;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
