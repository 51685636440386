.tc-website-latestarticles {
    margin-bottom: 88px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 88px;

        .title {
            strong {
                font-weight: $medium;
                color: $purple;
            }
        }

        .button {
            @include make-button;
        }
    }

    .blog-articles-wrapper {
        @include make-row;

        .tc-website-blogarticleslistitem {
            @include make-col(4);

            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 75px;

        .header {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
            margin-bottom: 75px;
        }

        .blog-articles-wrapper {
            gap: 75px;

            .tc-website-blogarticleslistitem {
                @include make-col($grid-columns);
            }
        }
    }

    + .nodetypes-content {
        margin-top: 72px;

        &.tc-website-blogevents-slider {
            margin-top: 0;
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
