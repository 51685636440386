.tc-website-testimonialslider {
    padding: $margin-section 0;
    margin-bottom: 0;
    background-image: url('data:image/svg+xml,<svg width="399" height="593" viewBox="0 0 399 593" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_ii_4461_583)"><path d="M150.895 295.261L303.969 0H153.068L-0.00619507 295.261V297.739L153.068 593H303.969L150.895 297.739V295.261Z" fill="%23EBF0F5"/><path d="M419.834 295.261L573.062 0H422.316L269.088 295.261V297.739L422.316 593H573.062L419.834 297.739V295.261Z" fill="%23EBF0F5"/></g><defs><filter id="filter0_ii_4461_583" x="-10.0062" y="-10" width="593.069" height="613" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="10" dy="10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.858824 0 0 0 0 0.87451 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4461_583"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-10" dy="-10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4461_583" result="effect2_innerShadow_4461_583"/></filter></defs></svg>');
    background-repeat: no-repeat;
    background-position: top $margin-section right;
    background-size: 399px 593px;

    .testimonial-slider-container {
        @include make-container;
        @include make-container-max-widths;

        .testimonial-slider-wrapper {
            display: flex;
            flex-direction: column;
            gap: 95px;

            .title {
                max-width: percentage(divide(6, $grid-columns));

                strong {
                    font-weight: $medium;
                    color: $green-text;
                }
            }

            .tc-neos-elements-swiper {
                max-width: 100%;
                margin-bottom: 0;

                .swiper-pagination {
                    .swiper-pagination-bullet-active {
                        background-color: $green;
                        box-shadow: -10px -10px 20px 0px #4EE5B5 inset, 10px 10px 20px 0px #33AA84 inset;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 95px 0;
        background-image: none;

        .testimonial-slider-container {
            @include make-container($gutter-container-mobile);

            .testimonial-slider-wrapper {
                gap: 75px;

                .title {
                    max-width: none;
                }
            }
        }
    }

    + .tc-website-rootcontainer {
        padding-top: 0;
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
