// Bootstrap override

/* =Basic CSS
--------------------------------------------------------------------------------------------------*/
html {
    overflow-x: hidden;
    overflow-y: scroll !important;
    margin: 0 !important;

    &.no-smooth-scroll {
        scroll-behavior: auto !important;
    }

    &.active-mobile-menu {
        @include media-breakpoint-down(sm) {
            overflow-y: hidden !important;

            body {
                overflow-y: hidden;
                position: fixed;
            }
        }
    }
}

a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline: none !important;
}

*:focus {
    outline: none !important;
}

body {
    color: $font-color;
    background-color: $white;
    font-family: $lexend;
    font-size: 18px;
    line-height: 26px;
    font-weight: $light;

    @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 22px;
    }
}

h1 {
    font-size: 60px;
    line-height: 70px;
    font-weight: $light;
    margin: 0;

    @include media-breakpoint-down(lg) {
        font-size: 36px;
        line-height: 39px;
    }
}

h2 {
    font-size: 34px;
    font-weight: $medium;
    line-height: 38px;
    margin: 0;

    @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 28px;
    }
}

h3 {
    font-size: 26px;
    line-height: 32px;
    font-weight: $medium;
    margin: 0;

    @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 27px;
    }
}

a {
    color: $link-color;
    text-decoration: underline;
    text-decoration-color: $link-color;
    transition: text-decoration-color .4s ease-in-out;

    &:hover {
        text-decoration-color: transparent;
    }
}

p {
    margin-bottom: 44px;

    @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

blockquote {
    font-size: 26px;
    font-weight: $light;
    line-height: 36px;
}

img {
    @include img-fluid();
}

figure {
    margin: 0;
}

.alert.alert-info {
    background-color: $blue-light;
    border-color: $blue-background;
    margin-bottom: 40px;
}

#fb-root {
    display: none !important;
}

main#global-container {
    > .mm-page {
        display: none !important;
    }
}

body.iphone {
    .btn.btn-default {
        transition: none !important;
    }
}
