.tc-website-calltoaction {
    .cta-wrapper {
        @include make-row;
        width: 100%;

        .cta-image,
        .right-content {
            @include make-col-ready;
        }

        .cta-image {
            @include make-col(5);
            @include make-col-offset(1);

            margin-top: 7px;

            img {
                width: 100%;
            }
        }

        .right-content {
            @include make-col(4);
            @include make-col-offset(1);

            display: flex;
            flex-direction: column;
            gap: 44px;

            .text-content {
                display: flex;
                flex-direction: column;
                gap: 24px;

                .title {
                    strong {
                        color: $green-text;
                        font-weight: $medium;
                    }
                }
            }

            .cta-button {
                @include make-button;

                .ck-editor__editable_inline {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }

    &.right-image {
        .cta-wrapper {
            .cta-image {
                order: 2;
            }

            .right-content {
                order: 1;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .cta-wrapper {
            gap: 30px;
            width: auto;

            .cta-image,
            .right-content {
                @include make-col(12);
                @include make-col-offset(0);
            }

            .cta-image {
                margin-top: 0;
            }

            .right-content {
                gap: 30px;

                .text-content {
                    gap: 15px;
                }
            }
        }

        &.right-image {
            .cta-wrapper {
                .cta-image {
                    order: 1;
                }

                .right-content {
                    order: 2;
                }
            }
        }
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
