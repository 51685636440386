.tc-website-accordionblock {
    padding: $margin-section 0;
    margin-bottom: 0;

    .accordion-block-container {
        @include make-container;
        @include make-container-max-widths;

        .accordion-block-header {
            @include make-row();

            .text-content {
                @include make-col-ready;
                @include make-col(8);

                display: flex;
                flex-direction: column;
                gap: 26px;

                .title {
                    strong {
                        font-weight: $medium;
                        color: $green-text;
                    }
                }

                .description {
                    font-size: 26px;
                    font-weight: $light;
                    line-height: 36px;
                }
            }
        }

        .accordion-wrapper {
            @include make-row();

            margin-top: 87px;
            background-image: url('data:image/svg+xml,<svg width="371" height="383" viewBox="0 0 371 383" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_ii_4461_997)"><path d="M272.67 190.7L173.805 0H271.267L370.132 190.7V192.3L271.267 383H173.805L272.67 192.3V190.7Z" fill="%23E1EBF5"/><path d="M98.9652 190.7L0 0H97.3625L196.328 190.7V192.3L97.3625 383H0L98.9652 192.3V190.7Z" fill="%23E1EBF5"/></g><defs><filter id="filter0_ii_4461_997" x="-10" y="-10" width="390.132" height="403" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="10" dy="10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.787916 0 0 0 0 0.839508 0 0 0 0 0.89847 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4461_997"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-10" dy="-10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.932861 0 0 0 0 0.964193 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4461_997" result="effect2_innerShadow_4461_997"/></filter></defs></svg>');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 371px 383px;
            min-height: 383px;

            .tc-neos-elements-bootstrap-accordion {
                @include make-col-ready;
                @include make-col(8);
                @include make-col-offset(4);
            }

            @include media-breakpoint-down(xxl) {
                background-size: 350px 361px;
                min-height: 361px;
            }

            @include media-breakpoint-down(xl) {
                background-size: 300px 309px;
                min-height: 309px;
            }
        }
    }

    &.background-color-gray {
        + .tc-website-rootcontainer,
        + .tc-website-featuredproducts {
            padding-top: 0;
        }
    }



    @include media-breakpoint-down(lg) {
        padding: 95px 0;

        .accordion-block-container {
            @include make-container($gutter-container-mobile);

            .accordion-block-header {
                margin-bottom: 75px;

                .text-content {
                    @include make-col(12);

                    gap: 15px;

                    .description {
                        font-size: 20px;
                        line-height: 27px;
                    }
                }
            }

            .accordion-wrapper {
                margin-top: 75px;
                background-image: none;
                min-height: 0;

                .tc-neos-elements-bootstrap-accordion {
                    @include make-col(12);
                    @include make-col-offset(0);
                }
            }
        }

        + .tc-website-teamblock {
            padding-top: 0;
        }
    }

    &.background-color-gray {
        background-color: $gray;

        .accordion-wrapper {
            background-image: url('data:image/svg+xml,<svg width="371" height="383" viewBox="0 0 371 383" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_ii_4492_2881)"><path d="M272.67 190.7L173.805 0H271.267L370.132 190.7V192.3L271.267 383H173.805L272.67 192.3V190.7Z" fill="%23EBF0F5"/><path d="M98.9652 190.7L0 0H97.3625L196.328 190.7V192.3L97.3625 383H0L98.9652 192.3V190.7Z" fill="%23EBF0F5"/></g><defs><filter id="filter0_ii_4492_2881" x="-10" y="-10" width="390.133" height="403" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="10" dy="10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.858824 0 0 0 0 0.87451 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4492_2881"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-10" dy="-10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4492_2881" result="effect2_innerShadow_4492_2881"/></filter></defs></svg>');

            @include media-breakpoint-down(lg) {
                background-image: none;
            }
        }
    }

    &.background-color-blue-light {
        background-color: $blue-light;
    }

    .ck-placeholder:before {
        color: #000 !important;
    }
}
