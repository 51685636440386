#tarteaucitronRoot {
    * {
        font-family: $lexend;
    }

    #tarteaucitronAlertBig {
        justify-content: space-around;
        padding: 0 !important;
        background: $blue-light !important;

        #tarteaucitronDisclaimerAlert {
            max-width: 34%;
            white-space: wrap;
            color: #000;
            border-bottom: none !important;

            @include media-breakpoint-down(lg) {
                max-width: none;
                padding-bottom: 0;
            }
        }

        .tarteaucitronCTAButton,
        #tarteaucitronCloseAlert,
        #tarteaucitronPrivacyUrl {
            font-weight: $light;
            text-decoration-color: #000;
            color: #000;
            transition: text-decoration-color .4s ease-in-out;

            &:hover {
                text-decoration-color: transparent;
            }
        }

        .tarteaucitronCTAButton {
            &.tarteaucitronAllow,
            &.tarteaucitronDeny {
                color: #000;
                text-decoration-color: #000;
                transition: text-decoration-color .4s ease-in-out;

                &:hover {
                    text-decoration-color: transparent;
                }
            }

            &.tarteaucitronAllow {
                background-color: $green-text;
            }

            &.tarteaucitronDeny {
                background-color: $blue-light;
            }
        }
    }

    #tarteaucitron {
        background-color: $gray !important;

        #tarteaucitronServices {
            margin-top: 0 !important;
            border-radius: 0 !important;

            @include media-breakpoint-up(lg) {
                height: auto !important;
            }

            .tarteaucitronMainLine {
                background-color: $gray !important;

                .tarteaucitronH1 {
                    font-size: 34px;
                    font-weight: $medium;
                    line-height: 38px;

                    @include media-breakpoint-down(lg) {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }

                #tarteaucitronInfo {
                    background: $gray !important;
                    border: none !important;
                    font-weight: $light !important;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 30px !important;
                    }
                }

                * {
                    color: #000 !important;
                }

                .tarteaucitronAllow {
                    color: $green-text !important;
                    opacity: 1 !important;

                    &:hover,
                    &[aria-pressed="true"] {
                        color: $white !important;
                    }
                }

                .tarteaucitronDeny {
                    &:hover,
                    &[aria-pressed="true"] {
                        color: $white !important;
                        opacity: 1 !important;
                    }
                }

                @include media-breakpoint-down(lg) {
                    .tarteaucitronAsk {
                        display: flex !important;
                        gap: 15px;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                }
            }

            .tarteaucitronBorder {
                background: $gray !important;

                .tarteaucitronHidden {
                    background-color: $blue-light !important;
                }

                .tarteaucitronTitle {
                    background-color: $gray !important;

                    button {
                        background-color: $blue-light !important;
                        color: #000 !important;

                        .tarteaucitronPlus {
                            &:before {
                                color: #000 !important;
                            }
                        }
                    }
                }

                #tarteaucitronServices_api,
                #tarteaucitronServices_analytic {
                    .tarteaucitronLine {
                        background-color: $blue-light !important;

                        @include media-breakpoint-down(lg) {
                            .tarteaucitronAsk {
                                display: flex !important;
                                gap: 30px;
                            }
                        }
                    }
                }
            }

            #tarteaucitronSave {
                background-color: $gray !important;
            }
        }

        .tarteaucitronAllow,
        #tarteaucitronPrivacyUrlDialog,
        .tarteaucitronDeny,
        #tarteaucitronSaveButton {
            transition: all .4s ease-in-out !important;
            padding: 12px 26px !important;
            font-size: 16px !important;
            line-height: 20px !important;
            font-weight: $medium !important;
            border-radius: 50px !important;
            border: 1.5px solid #000 !important;
            color: #000 !important;
            background-color: $gray !important;
            opacity: 1 !important;

            &:hover,
            &[aria-pressed="true"]{
                background: #000 !important;
                color: $white !important;
                opacity: 1 !important;
            }

            @include media-breakpoint-down(lg) {
                padding: 6px 26px !important;
            }
        }

        .tarteaucitronAllow {
            border-color: $green-text !important;
            color: $green-text !important;
            opacity: 1 !important;

            &:hover,
            &[aria-pressed="true"] {
                background: $green-text !important;
                color: $white !important;
            }
        }

        #tarteaucitronClosePanel {
            background-color: $gray !important;
            color: #000 !important;
            font-weight: $light !important;
            top: -22px !important;
        }

        @at-root .tarteaucitron-modal-open & {
            @include media-breakpoint-down(lg) {
                max-width: 90% !important;
                margin: 0 auto !important;
                max-height: 90% !important;
                margin-top: auto !important;
                margin-bottom: auto !important;
            }
        }
    }
}

#tarteaucitronCloseCross {
    background-image: none !important;
}

.tc-neos-google-map-replace {
    button.tarteaucitronAllow {
        transition: all .4s ease-in-out !important;
        padding: 12px 26px !important;
        font-size: 16px !important;
        line-height: 20px !important;
        font-weight: $medium !important;
        border-radius: 50px !important;
        border: 1.5px solid #000 !important;
        color: #000 !important;
        background-color: $gray !important;

        &:hover,
        &[aria-pressed="true"]{
            background: #000 !important;
            color: $white !important;
            opacity: 1 !important;
        }

        @include media-breakpoint-down(lg) {
            padding: 6px 26px !important;
        }
    }
}

.tc-neos-google-map-replace {
    margin: 0 calc(percentage(divide(1, $grid-columns)) + $grid-gutter-width) !important;
    background-color: transparent !important;
    height: 260px !important;

    @include media-breakpoint-down(lg) {
        margin: 0 !important;
        height: 150px !important;
    }
}
