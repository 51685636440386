.tc-website-customercarousel {
    .title-container {
        @include make-container;
        @include make-container-max-widths;

        @include media-breakpoint-down(lg) {
            @include make-container($gutter-container-mobile);
        }

        h2 {
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 44px;

            strong {
                color: $green-text;
                font-weight: $medium;
            }

            .ck-placeholder {
                &:before {
                    color: #000;
                }
            }
        }
    }

    .logo-wrapper {
        width: 100%;
        position: relative;
        height: 100px;

        .tc-website-customercarouselitem {
            width: 20%;
            position: absolute;
            visibility: hidden;
            top: 50%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                display: grid;
                place-content: center;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            img {
                width: 100%;
                min-width: 180px;
                max-width: 180px;
                max-height: 100px;
            }
        }

        @include media-breakpoint-down(xxl) {
            height: 100px;

            .tc-website-customercarouselitem {
                width: 22%;

                img {
                    max-width: 170px;
                    min-width: 170px;
                    max-height: 100px;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            height: 90px;

            .tc-website-customercarouselitem {
                width: 26%;
                height: 90px;

                img {
                    max-width: 140px;
                    min-width: 140px;
                    max-height: 90px;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .tc-website-customercarouselitem {
                width: 35%;
            }
        }

        @include media-breakpoint-down(sm) {
            .tc-website-customercarouselitem {
                width: 55%;
            }
        }
    }
}

.neos-backend {
    .tc-website-customercarousel {
        .logo-wrapper {
            @include make-container;
            @include make-container-max-widths;

            overflow: visible;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .tc-website-customercarouselitem {
                visibility: visible;
                position: static;
                width: 25%;
                margin-bottom: $grid-gutter-width;

                a {
                    position: static;
                }
            }
        }
    }
}
