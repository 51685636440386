.tc-website-eventarticle {
    &.has-form {
        #main-container {
            > .neos-contentcollection {
                > .tc-website-rootcontainer {
                    &:last-child {
                        padding-bottom: 44px;
                    }
                }
            }

            > .button-container {
                @include make-container;
                @include make-container-max-widths;

                margin-bottom: $margin-section;

                .registration-button {
                    @include make-button;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &.has-form {
            #main-container {
                > .button-container {
                    @include make-container($gutter-container-mobile);

                    margin-bottom: 75px;
                }
            }
        }
    }
}
