form {
    label {
        font-size: 26px;
        font-weight: $medium;
        line-height: 32px;
        margin-bottom: 21px;
    }

    select {
        width: 100%;
        background-color: $gray;
        box-shadow: -10px -10px 20px 0px #FFF inset, 10px 10px 20px 0px #D3DBDF inset;
        border: 0;
        border-radius: 18px;
        padding: 31px 26px;
        appearance: none;

        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="27" height="16" viewBox="0 0 27 16" fill="none"><path d="M25 2L14 13L2 2" stroke="black" stroke-width="3"/></svg>');
        background-repeat: no-repeat;
        background-position: right 26px center;
        background-size: 23px 11px;

        font-size: 18px;
        font-weight: $light;
        line-height: 26px;
        color: #000;
    }

    input,
    textarea {
        width: 100%;
        border: 0;
        height: 88px;
        border-radius: 18px;
        background: $gray;
        padding: 30px 26px 32px;
        box-shadow: -10px -10px 20px 0px #FFF inset, 10px 10px 20px 0px #D3DBDF inset;
        appearance: none;

        color: #000;
        font-size: 18px;
        font-weight: $light;
        line-height: 26px;

        ::placeholder {
            color: rgba(#000, .5);
        }
    }

    textarea {
        height: 300px;
    }

    @include media-breakpoint-down(lg) {
        label {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 30px;
        }

        select {
            padding: 13px 50px 16px 15px;
            font-size: 16px;
            line-height: 22px;
            border-radius: 6px;
            box-shadow: -6px -6px 10px 0px #FFF inset, 6px 6px 10px 0px #D3DBDF inset;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none"><path d="M1 1.61572L6.65685 7.27258L12.3137 1.61572" stroke="black" stroke-width="2"/></svg>');
            background-size: 14px 9px;
            background-position: right 19px center
        }

        input,
        textarea {
            font-size: 16px;
            line-height: 22px;
            padding: 13px 15px 16px;
            border-radius: 6px;
            height: 52px;
            box-shadow: -6px -6px 10px 0px #FFF inset, 6px 6px 10px 0px #D3DBDF inset;
        }

        textarea {
            height: 150px;
        }
    }
}
