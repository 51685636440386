.global-container {
    > footer {
        background-color: $blue-dark;
        padding: 95px 0 185px;
        color: $white;
        font-weight: $light;

        .footer-container {
            @include make-container;
            @include make-container-max-widths;

            .footer-wrapper {
                @include make-row;

                .logo,
                .slogan,
                .coordinates,
                .footer-menu-wrapper {
                    @include make-col-ready;
                }

                .logo {
                    @include make-col(2);
                }

                .slogan {
                    @include make-col(3);

                    font-weight: $medium;
                    font-size: 16px;
                    line-height: 18px;
                    padding-left: 68px;
                    padding-top: 5px;
                }

                .coordinates {
                    @include make-col(2);
                    @include make-col-offset(2);

                    p {
                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        strong {
                            font-weight: $medium;
                        }
                    }
                }

                .footer-menu-wrapper {
                    @include make-col(2);
                    @include make-col-offset(1);

                    ul.footer-menu {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;

                        li {
                        }
                    }

                    .copyrights {
                        margin-top: 24px;
                    }
                }

                a {
                    transition: text-decoration-color .4s ease-in-out;
                    text-decoration-color: #fff;

                    &:hover {
                        color: #fff;
                        text-decoration-color: transparent;
                    }
                }
            }
        }

        a {
            transition: color .4s ease-in-out;
            color: $white;

            &:hover {
                color: $green;
            }
        }

        @include media-breakpoint-down(lg) {
            padding: 75px 0;
            font-size: 16px;
            line-height: 22px;

            .footer-container {
                @include make-container($gutter-container-mobile);

                .footer-wrapper {
                    .logo {
                        @include make-col(6);
                        margin-bottom: 70px;

                        svg {
                            width: 160px;
                        }
                    }

                    .slogan {
                        @include make-col(5);
                        @include make-col-offset(1);

                        font-size: 13px;
                        line-height: 18px;
                        padding-left: 0;
                        padding-top: 6px;
                    }

                    .coordinates,
                    .footer-menu-wrapper {
                        @include make-col(12);
                        @include make-col-offset(0);
                    }

                    .coordinates {
                        margin-bottom: 65px;
                    }
                }
            }
        }

        .ck-placeholder:before {
            color: $white !important;
        }
    }
}
