.tc-website-home {
    .global-container {
        #main-container {
            .tc-website-pageintroduction {
                margin-bottom: 0;

                .page-introduction-container {
                    @include make-container;
                    @include make-container-max-widths;

                    margin: auto !important;

                    .page-introduction-wrapper,
                    .tc-website-homeslider {
                        @include make-col-ready;
                        @include make-col(6);

                        padding-right: 0;
                        padding-left: 0;
                    }

                    .page-introduction-wrapper {
                        .introduction-title {
                            max-width: 540px;
                            font-size: 60px;
                            font-weight: $light;
                            line-height: 70px;
                            padding: 0;
                            margin-bottom: 96px;

                            strong {
                                font-weight: $light;
                                color: $green-text;

                                @at-root .color-purple & {
                                    color: $purple;
                                }
                            }
                        }

                        .introduction-logo {
                            width: 548px;
                            height: 371px;
                            background-image: url('data:image/svg+xml,<svg width="548" height="371" viewBox="0 0 548 371" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_ii_4461_579)"><path d="M263.762 184.486L168.117 0H262.404L358.048 184.486V186.035L262.404 370.522H168.117L263.762 186.035V184.486Z" fill="%23EBF0F5"/><path d="M452.261 184.486L547.808 0H453.424L358.07 184.486V186.035L453.811 370.522H548.002L452.261 186.035V184.486Z" fill="%23EBF0F5"/><path d="M95.7409 184.486L0 0H94.1904L189.931 184.486V186.035L94.1904 370.522H0L95.7409 186.035V184.486Z" fill="%23EBF0F5"/></g><defs><filter id="filter0_ii_4461_579" x="-10" y="-10" width="568.002" height="390.521" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="10" dy="10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.858824 0 0 0 0 0.87451 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4461_579"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-10" dy="-10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4461_579" result="effect2_innerShadow_4461_579"/></filter></defs></svg>');
                            background-repeat: no-repeat;
                            margin-top: $margin-section;
                        }
                    }

                    &.has-slider {
                        @include make-row;

                        .page-introduction-wrapper {
                            flex: 0 0 auto;
                        }
                    }
                }

                .home-introduction-text-container {
                    @include make-container;
                    @include make-container-max-widths;

                    .introduction-text {
                        max-width: percentage(divide(8, $grid-columns));
                        font-size: 26px;
                        font-weight: $light;
                        line-height: 36px;
                        margin-top: $margin-section;
                    }

                    .page-introduction-button {
                        @include make-button;

                        .ck-editor__editable {
                            position: relative;
                            z-index: 2;
                        }

                        .ck-placeholder:before {
                            color: #000 !important;
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    .page-introduction-container {
                        padding-left: $gutter-container-mobile * .5 !important;
                        padding-right: $gutter-container-mobile * .5 !important;
                        //padding-bottom: 262px;
                        //background-size: 247px 167px;
                        //background-position: center bottom;

                        .page-introduction-wrapper,
                        .tc-website-homeslider {
                            @include make-col-ready;
                            @include make-col(12);

                            padding: 0;
                        }

                        .page-introduction-wrapper {
                            .introduction-title {
                                font-size: 40px;
                                line-height: 46px;
                                margin-bottom: 75px;
                            }

                            .introduction-logo {
                                display: none;
                                background-image: none;
                            }

                            .page-introduction-button {
                                margin-bottom: 75px;
                            }
                        }

                        .tc-website-homeslider {
                            margin-left: $gutter-container-mobile * -.5;
                            margin-right: $gutter-container-mobile * -.5;
                            width: 100vw;
                            max-width: none;
                        }
                    }

                    .home-introduction-text-container {
                        @include make-container($gutter-container-mobile);

                        .introduction-text {
                            font-size: 20px;
                            line-height: 27px;
                            max-width: none;
                            padding-top: 357px;
                            margin-top: 0;

                            background-image: url('data:image/svg+xml,<svg width="548" height="371" viewBox="0 0 548 371" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_ii_4461_579)"><path d="M263.762 184.486L168.117 0H262.404L358.048 184.486V186.035L262.404 370.522H168.117L263.762 186.035V184.486Z" fill="%23EBF0F5"/><path d="M452.261 184.486L547.808 0H453.424L358.07 184.486V186.035L453.811 370.522H548.002L452.261 186.035V184.486Z" fill="%23EBF0F5"/><path d="M95.7409 184.486L0 0H94.1904L189.931 184.486V186.035L94.1904 370.522H0L95.7409 186.035V184.486Z" fill="%23EBF0F5"/></g><defs><filter id="filter0_ii_4461_579" x="-10" y="-10" width="568.002" height="390.521" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="10" dy="10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.858824 0 0 0 0 0.87451 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect1_innerShadow_4461_579"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="-10" dy="-10"/><feGaussianBlur stdDeviation="10"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend mode="normal" in2="effect1_innerShadow_4461_579" result="effect2_innerShadow_4461_579"/></filter></defs></svg>');
                            background-repeat: no-repeat;
                            background-size: 247px 167px;
                            background-position: center top 95px;
                        }
                    }
                }
            }
        }
    }
}
