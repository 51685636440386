.tc-website-imagefullwidth {
    margin-bottom: 0;
    overflow: hidden;
    height: 58vh;

    picture {
        display: block;
        overflow: hidden;

        img {
            width: 100%;
            transform: scale(1.4);
        }
    }

    + .neos-nodetypes-contentreferences-contentreferences {
        .nodetypes-content:first-child {
            &.tc-website-calltoaction {
                margin-top: $margin-section;
            }
        }
    }
}
