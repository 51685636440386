// Placeholders vendor prefixes
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

// Use before pseudo element to give element the giver width and height before its content is loaded
@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc((#{$height} / #{$width}) * 100%);
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// Containers max widths
@mixin make-container-max-widths($max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

// Xamplo mixins
@mixin make-button() {
    .btn {
        transition: all .4s ease-in-out;

        &.btn-default,
        &.btn-3d {
            padding: 12px 26px;
            font-size: 16px;
            line-height: 20px;
            font-weight: $medium;
            border-radius: 50px;

            @include media-breakpoint-down(lg) {
                padding: 6px 26px;
            }
        }

        &.btn-default {
            border: 1.5px solid #000;

            &:hover {
                background: #000;
                color: $white;
            }
        }

        &.btn-3d {
            position: relative;
            background-color: $gray;

            &:before,
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 50px;
                transition: opacity .4s ease-in-out;
            }

            &:before {
                box-shadow: -10px -10px 20px 0px #FFF, 10px 10px 20px 0px #D3DBDF;
                opacity: 1;
            }

            &:after {
                box-shadow: -10px -10px 20px 0px #FFF inset, 10px 10px 20px 0px #D3DBDF inset;
                opacity: 0;
            }

            span {
                position: relative;
                z-index: 1;
            }

            &:hover {
                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:active {
                border-color: transparent;
            }
        }
    }
}
