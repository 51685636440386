nav.menu {
    ul.main-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 36px;
        align-items: center;

        > li {
            a {
                transition: color .4s ease-in-out;
                font-size: 16px;
                font-weight: $medium;
                line-height: 20px;
                color: #000;
                text-decoration: none;

                &:hover {
                    color: $green-text;
                }
            }

            &.active,
            &.current {
                a {
                    color: $green-text;
                }
            }

            &.purple {
                a {
                    &:hover {
                        color: $purple;
                    }
                }

                &.active,
                &.current {
                    a {
                        color: $purple;
                    }
                }
            }

            &.project-request-link {
                @include make-button;

                margin-left: 21px;

                .ck-placeholder:before {
                    color: #000 !important;
                }
            }

            ul {
                display: none; // hide submenu on load
                position: absolute;
                background: white;
            }
        }

        @include media-breakpoint-down(xl) {
            gap: 16px;

            > li {
                &.project-request-link {
                    margin-left: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}
