.global-container {
    > header {
        transition: all .4s ease-in-out;
        position: fixed;
        width: 100%;
        top: 0;
        padding: 59px 0;
        background-color: $white;
        z-index: 10;

        .header-container {
            @include make-container;
            @include make-container-max-widths;

            .header-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    svg {
                        path#first-letter {
                            transition: fill .4s ease-in-out;
                            fill: $green;
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    .logo {
                        svg {
                            width: 160px;
                        }
                    }
                }
            }
        }

        @at-root .mobile-menu-opened & {
            background-color: $green;

            .header-container {
                .header-wrapper {
                    .logo {
                        svg {
                            path#first-letter {
                                fill: $white;
                            }
                        }
                    }
                }
            }
        }

        @at-root .minimize-header & {
            position: fixed;
            top: calc(#{$heightHeader} * -1);
            width: 100%;
        }

        @at-root .minimize-header.show-header & {
            top: 0;
        }

        @include media-breakpoint-down(lg) {
            padding: 30px 0;

            .header-container {
                @include make-container($gutter-container-mobile);
            }
        }
    }

    .mmenu-navbars {
        display: none;
    }
}
